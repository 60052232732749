var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  var e = {
    LTS: "h:mm:ss A",
    LT: "h:mm A",
    L: "MM/DD/YYYY",
    LL: "MMMM D, YYYY",
    LLL: "MMMM D, YYYY h:mm A",
    LLLL: "dddd, MMMM D, YYYY h:mm A"
  },
      t = /(\[[^[]*\])|([-_:/.,()\s]+)|(A|a|YYYY|YY?|MM?M?M?|Do|DD?|hh?|HH?|mm?|ss?|S{1,3}|z|ZZ?)/g,
      n = /\d\d/,
      r = /\d\d?/,
      i = /\d*[^-_:/,()\s\d]+/,
      o = {},
      s = function (e) {
    return (e = +e) + (e > 68 ? 1900 : 2000);
  };

  var a = function (e) {
    return function (t) {
      (this || _global)[e] = +t;
    };
  },
      f = [/[+-]\d\d:?(\d\d)?|Z/, function (e) {
    ((this || _global).zone || ((this || _global).zone = {})).offset = function (e) {
      if (!e) return 0;
      if ("Z" === e) return 0;
      var t = e.match(/([+-]|\d\d)/g),
          n = 60 * t[1] + (+t[2] || 0);
      return 0 === n ? 0 : "+" === t[0] ? -n : n;
    }(e);
  }],
      h = function (e) {
    var t = o[e];
    return t && (t.indexOf ? t : t.s.concat(t.f));
  },
      u = function (e, t) {
    var n,
        r = o.meridiem;

    if (r) {
      for (var i = 1; i <= 24; i += 1) if (e.indexOf(r(i, 0, t)) > -1) {
        n = i > 12;
        break;
      }
    } else n = e === (t ? "pm" : "PM");

    return n;
  },
      d = {
    A: [i, function (e) {
      (this || _global).afternoon = u(e, !1);
    }],
    a: [i, function (e) {
      (this || _global).afternoon = u(e, !0);
    }],
    S: [/\d/, function (e) {
      (this || _global).milliseconds = 100 * +e;
    }],
    SS: [n, function (e) {
      (this || _global).milliseconds = 10 * +e;
    }],
    SSS: [/\d{3}/, function (e) {
      (this || _global).milliseconds = +e;
    }],
    s: [r, a("seconds")],
    ss: [r, a("seconds")],
    m: [r, a("minutes")],
    mm: [r, a("minutes")],
    H: [r, a("hours")],
    h: [r, a("hours")],
    HH: [r, a("hours")],
    hh: [r, a("hours")],
    D: [r, a("day")],
    DD: [n, a("day")],
    Do: [i, function (e) {
      var t = o.ordinal,
          n = e.match(/\d+/);
      if ((this || _global).day = n[0], t) for (var r = 1; r <= 31; r += 1) t(r).replace(/\[|\]/g, "") === e && ((this || _global).day = r);
    }],
    M: [r, a("month")],
    MM: [n, a("month")],
    MMM: [i, function (e) {
      var t = h("months"),
          n = (h("monthsShort") || t.map(function (e) {
        return e.slice(0, 3);
      })).indexOf(e) + 1;
      if (n < 1) throw new Error();
      (this || _global).month = n % 12 || n;
    }],
    MMMM: [i, function (e) {
      var t = h("months").indexOf(e) + 1;
      if (t < 1) throw new Error();
      (this || _global).month = t % 12 || t;
    }],
    Y: [/[+-]?\d+/, a("year")],
    YY: [n, function (e) {
      (this || _global).year = s(e);
    }],
    YYYY: [/\d{4}/, a("year")],
    Z: f,
    ZZ: f
  };

  function c(n) {
    var r, i;
    r = n, i = o && o.formats;

    for (var s = (n = r.replace(/(\[[^\]]+])|(LTS?|l{1,4}|L{1,4})/g, function (t, n, r) {
      var o = r && r.toUpperCase();
      return n || i[r] || e[r] || i[o].replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, function (e, t, n) {
        return t || n.slice(1);
      });
    })).match(t), a = s.length, f = 0; f < a; f += 1) {
      var h = s[f],
          u = d[h],
          c = u && u[0],
          l = u && u[1];
      s[f] = l ? {
        regex: c,
        parser: l
      } : h.replace(/^\[|\]$/g, "");
    }

    return function (e) {
      for (var t = {}, n = 0, r = 0; n < a; n += 1) {
        var i = s[n];
        if ("string" == typeof i) r += i.length;else {
          var o = i.regex,
              f = i.parser,
              h = e.slice(r),
              u = o.exec(h)[0];
          f.call(t, u), e = e.replace(u, "");
        }
      }

      return function (e) {
        var t = e.afternoon;

        if (void 0 !== t) {
          var n = e.hours;
          t ? n < 12 && (e.hours += 12) : 12 === n && (e.hours = 0), delete e.afternoon;
        }
      }(t), t;
    };
  }

  return function (e, t, n) {
    n.p.customParseFormat = !0, e && e.parseTwoDigitYear && (s = e.parseTwoDigitYear);
    var r = t.prototype,
        i = r.parse;

    r.parse = function (e) {
      var t = e.date,
          r = e.utc,
          s = e.args;
      (this || _global).$u = r;
      var a = s[1];

      if ("string" == typeof a) {
        var f = !0 === s[2],
            h = !0 === s[3],
            u = f || h,
            d = s[2];
        h && (d = s[2]), o = this.$locale(), !f && d && (o = n.Ls[d]), (this || _global).$d = function (e, t, n) {
          try {
            if (["x", "X"].indexOf(t) > -1) return new Date(("X" === t ? 1000 : 1) * e);
            var r = c(t)(e),
                i = r.year,
                o = r.month,
                s = r.day,
                a = r.hours,
                f = r.minutes,
                h = r.seconds,
                u = r.milliseconds,
                d = r.zone,
                l = new Date(),
                m = s || (i || o ? 1 : l.getDate()),
                M = i || l.getFullYear(),
                Y = 0;
            i && !o || (Y = o > 0 ? o - 1 : l.getMonth());
            var p = a || 0,
                v = f || 0,
                D = h || 0,
                g = u || 0;
            return d ? new Date(Date.UTC(M, Y, m, p, v, D, g + 60 * d.offset * 1000)) : n ? new Date(Date.UTC(M, Y, m, p, v, D, g)) : new Date(M, Y, m, p, v, D, g);
          } catch (e) {
            return new Date("");
          }
        }(t, a, r), this.init(), d && !0 !== d && ((this || _global).$L = this.locale(d).$L), u && t != this.format(a) && ((this || _global).$d = new Date("")), o = {};
      } else if (a instanceof Array) for (var l = a.length, m = 1; m <= l; m += 1) {
        s[1] = a[m - 1];
        var M = n.apply(this || _global, s);

        if (M.isValid()) {
          (this || _global).$d = M.$d, (this || _global).$L = M.$L, this.init();
          break;
        }

        m === l && ((this || _global).$d = new Date(""));
      } else i.call(this || _global, e);
    };
  };
});
export default exports;